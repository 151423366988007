/* eslint-disable react/forbid-prop-types */
import React from "react";
import { graphql } from "gatsby";
import Layout from "@/Layout";

const Post = ({ data, location }) => {
  const { seo, title, date } = data.wpPost;
  return (
    <Layout seo={seo} location={location}>
      <div>Post: {title}</div>
      <div>Date: {date}</div>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      title
      date(formatString: "MMMM DD, YYYY")
      content
      featuredImage {
        ...FeaturedImage
      }
      ...PostSEO
    }
  }

  fragment FeaturedImage on WpNodeWithFeaturedImageToMediaItemConnectionEdge {
    node {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 90)
        }
      }
    }
  }
`;

export default Post;
